<template>
	<ion-grid class="p-0">
		<ion-row>
			<ion-col size="4" size-sm="3" v-for="(video, videoIndex) in videos" :key="`video-${videoIndex}`">
				<div class="shot-wrapper d-flex">
					<img :src="video.content_thumbnail" @click="$router.push({name: 'theme-feed', params: {themeSlug: $route.params.themeSlug, videoId: video.id}})">
					<div class="video-information-wrapper ml-2 mt-1">
						<p>{{ video.content_description || video.name }}</p>
						<p class="tags">#{{ video.tags.join(", #") }}</p>
						
						<div fake class="creator d-flex ion-align-items-center">
							<img src="../../../lib@course/course/img/avatar.png" alt="">
							<p class="mb-0 ml-1">Creator</p>
						</div>
					</div>
				</div>
			</ion-col>
		</ion-row>
	</ion-grid>
</template>

<script>
export default {
	props: {
		videos: {
			type: Array,
			required: true
		}
	}
}
</script>

<style lang="sass" scoped>
ion-grid
	min-height: 600px

ion-col
	padding: 3px

	img
		border-radius: 4px
		margin: 0
		display: block
		cursor: pointer

	.video-information-wrapper
		display: none

// @media only screen and (min-width: 576px)
// 	ion-col
// 		.shot-wrapper
// 			width: 450px
// 			margin: auto
// 			padding: 10px 25px 15px
// 			border-bottom: 1px solid rgba(0, 0, 0, 0.15)
			
// 			img
// 				width: 300px
// 				height: 415px
// 				object-position: 0 0
			
// 		.video-information-wrapper
// 			display: block !important
// 			width: calc( 100% - 220px )

// 			img
// 				width: 30px
// 				height: 30px

// 	ion-col:last-child
// 		.shot-wrapper
// 			border-bottom: none !important

</style>