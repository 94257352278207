<template>
	<ion-col size="12" size-md="6">
		<ion-card class="ion-no-margin" :class="{done: course.done_by_active_user}"> <!-- Pre oznacenie lekcie ako dokoncenej pridat clasu "done" -->
			
			<div class="course-options-button">
				<ion-icon class="size-m" @click.stop="openPopover" :icon="ellipsisHorizontal"></ion-icon>
			</div>

			<div class=" width-100 d-flex">
				<img v-if="course.image?.path" class="mr-2" :src="course.image?.path">
				<img v-else class="mr-2" src="../../themes/img/placeholder-img.jpg">
				<h4 class="mt-1 pr-3">{{ course.name }}</h4>
			</div>

			<p class="vhtml mt-1" v-html="course.description" />
			<div v-if="course.done_by_active_user" class="d-flex ion-justify-content-between mt-1">
				<p class="primary mb-0"><b>Zobraziť úlohy</b></p>
				
				<div class="done-mark d-flex ion-align-items-center">
					<ion-icon :icon="checkmarkCircle"></ion-icon>
					<p class="mb-0"><b>Hotový</b></p>
				</div>
			</div>
		</ion-card>
	</ion-col>
</template>

<script>
import APopOver 				from '@/plugins/lib@enum/theme/components/a-popover.vue'
import { checkmarkCircle, ellipsisHorizontal } from 'ionicons/icons'
import { popoverController } 	from '@ionic/vue'

export default {
	props: {
		course: {
			type: Object,
			required: true,
		}
	},
	
	data() {
		return {
			checkmarkCircle,
			ellipsisHorizontal,
			popover: null,
		}
	},

	methods: {
		async openPopover(ev) {
			this.popover = await popoverController.create({
				component: APopOver,
				// cssClass: 'popover',
				event: ev,
				translucent: true,
				componentProps: {
					vm: this,
					course: this.course
				}
			})

			await this.popover.present()
			
			//dismiss modal after clicking away
			const dismiss = () => {this.popover.dismiss()}
			window.addEventListener('click', function handler(e) {
				dismiss()
				e.currentTarget.removeEventListener(e.type, handler)
			})
		}
	},
}
</script>

<style lang="sass" scoped>
ion-card
	border: 1px solid var(--ion-color-primary-contrast)
	img
		border-radius: 8px
		width: 85px
		height: 85px

.course-options-button
	position: absolute
	right: 15px
	top: 10px

	ion-icon
		color: rgba(0, 0, 0, 0.4)

.done-mark
	color: var(--ion-color-success)
	display: none
	margin-right: 5px

	ion-icon
		height: 16px
		width: 16px
		margin-right: 3px
		margin-bottom: 2px

	p
		color: inherit

.done
	border: 1px solid var(--ion-color-success) !important

	.done-mark
		display: flex !important

</style>