<template>
	<ion-content class="content-padding">
		<div v-if="isLoggedIn" class="d-flex ion-align-items-center ion-justify-content-evenly">
			<div @click.stop="toggleBookmark()" class="button d-flex flex-direction-column ion-align-items-center mt-1 mb-1">
				<ion-icon class="bookmark" :color="course.bookmark_by_active_user ? 'secondary' : ''" :icon="course.bookmark_by_active_user ? bookmarks : bookmarksOutline"></ion-icon>
				<ion-text>Založiť</ion-text>
			</div>

			<div @click.stop="toggleDone()" class="button d-flex flex-direction-column ion-align-items-center">
				<ion-icon :color="course.done_by_active_user ? 'success' : ''" :icon="course.done_by_active_user ? checkmarkDoneCircle : checkmarkDoneCircleOutline"></ion-icon>
				<ion-text>Hotové</ion-text>
			</div>
		</div>

		<div v-if="!isLoggedIn" @click="$router.push({name: 'login'})" 
			class="d-flex ion-align-items-center ion-justify-content-evenly mt-1 mb-1">
			<div class="button d-flex flex-direction-column ion-align-items-center">
				<ion-icon size="large" :icon="bookmarksOutline"></ion-icon>
				<ion-text>Založiť</ion-text>
			</div>
			<div class="button d-flex flex-direction-column ion-align-items-center">
				<ion-icon size="large" :icon="checkmarkDoneCircleOutline"></ion-icon>
				<ion-text>Hotové</ion-text>
			</div>
		</div>
	</ion-content>
</template>

<script>
import wAxios 			from '@/plugins/w/axios'
import { mapGetters} 	from 'vuex'
import { bookmarksOutline, checkmarkDoneCircleOutline, bookmarks, checkmarkDoneCircle } 	from 'ionicons/icons'

const api = {
	userflag: (model, id, data)	=> wAxios.post_auth_data(`v1/userflag/${model}/${id}`, data),
}

export default {
	props: {
		vm: {
			type: Object,
			required: true
		},

		course: {
			type: Object,
			required: true
		}
	},
	
	data() {
		return {
			bookmarksOutline,
			bookmarks,
			checkmarkDoneCircle,
			checkmarkDoneCircleOutline,
			disableBookmarks: false,
			disableDone: false,
		}
	},
	
	computed: {
		...mapGetters('wAuth', [
			'isLoggedIn'
		]),
	},



	methods: {
		async toggleBookmark() {
			// Fix to prevent new request before previous is finished
			if (this.disableBookmarks) return
			this.disableBookmarks = true

			const course = this.course
			const value = this.course.bookmark_by_active_user ? 0 : 1

			course.bookmark_by_active_user = value
			this.eventBus.emit('course:update', course)
			
			try {
				const data = {
					'type' : 'bookmark',
					'value': value
				}

				await api.userflag('course', course.id, data)
			} catch (error) {
				course.like_by_active_user = !value
				this.eventBus.emit('course:update', course)

				this.$wToast.error(error)
			}

			this.disableBookmarks = false
		},

		async toggleDone() {
			// Fix to prevent new request before previous is finished
			if (this.disableDone) return
			this.disableDone = true

			const course = this.course
			const value = this.course.done_by_active_user ? 0 : 1

			course.done_by_active_user = value
			this.eventBus.emit('course:update', course)
			
			try {
				const data = {
					'type' : 'done',
					'value': value
				}

				await api.userflag('course', course.id, data)
			} catch (error) {
				course.like_by_active_user = !value
				this.eventBus.emit('course:update', course)

				this.$wToast.error(error)
			}

			this.disableDone = false
		},
	},

	watch: {
		'$route'() {
			this.vm.popover.dismiss()
		}
	}
}
</script>

<style lang="sass" scoped>
ion-content
	--background: var(--ion-color-primary-contrast)

.button
	cursor: pointer

ion-text
	user-select: none
	
ion-icon
	height: 25px
	width: 25px

	&.bookmark
		margin-bottom: 5px
		height: 20px
		width: 20px

</style>