<template>
	<ion-page>
		<ion-header v-if="!videoFullscreen">
			<ion-toolbar>
				<!-- <ion-back-button :text="$isWeb.value ? 'Academy' : ''" slot="start" :defaultHref="{name: 'home'}" /> -->
				<ion-back-button text="" slot="start" :defaultHref="{name: 'home'}" />

				<ion-title>
					<ion-label v-if="theme">{{ theme.name }}</ion-label>
				</ion-title>
				<!-- <ion-title>
					<ion-searchbar class="header-search web-version" placeholder="Vyhľadaj" show-cancel-button="never"></ion-searchbar>
					<ion-label v-if="theme" class="mobile-version">{{ theme.name }}</ion-label>
				</ion-title> -->
				
				<div slot="end" class="user-wrapper" @click="$router.push(isLoggedIn ? `/profile/${user.id}` : '/login')">
					<p class="web-version">{{ isLoggedIn ? 'Profil' : 'Prihlásiť sa' }}</p>
					<ion-icon :icon="isLoggedIn ? personCircleOutline : logInOutline"></ion-icon>
				</div>
			</ion-toolbar>
		</ion-header>

		<ion-content :scroll-y="!disableScroll" :scroll-events="true" @ionScroll="contentScroll">
			<w-loadable :items="theme" skelet="spinner">

				<div class="theme-information-wrapper">
					<div class="container py-1 d-flex ion-justify-content-center ion-align-items-center">
						<img v-if="theme.image?.path" class="theme-img margin-auto mt-1" :src="theme.image?.path">
						<img v-else class="theme-img margin-auto mt-1" src="../themes/img/placeholder-img.jpg">
					</div>
					
					<hr>

					<div class="container px-1">
						<h4 class="large">{{ theme.name }}</h4>
						<p class="vhtml" v-html="theme.description" />

						<div v-if="isLoggedIn" class="status-wrapper d-flex ion-justify-content-between mt-2">
							<div class="d-flex ion-align-items-center">
								<ion-icon class="size-xxl mr-1" :icon="schoolOutline"></ion-icon>
								<p class="ion-no-margin"><b><span class="orange">{{ stats?.pending }}</span> NEMÁTE<br>OVERENÝCH</b></p>
							</div>

							<div class="d-flex ion-align-items-center">
								<ion-icon class="size-xxl" :icon="checkmarkDoneCircleOutline"></ion-icon>
								<p class="ion-no-margin"><b><span class="green">{{ stats?.verified }}</span> ÚLOH MÁTE<br>HOTOVÝCH</b></p>
							</div>
						</div>
					</div>
				</div>

				<div class="ion-padding container">
					<w-loadable :items="theme.courses" skelet="none" messageEmpty="Neboli nájdené žiadne kurzy pre túto tému">

						<ion-segment @ionChange="onChange($event.detail.value)" :value="activeTab">
							<ion-segment-button value="Projekty">
								<ion-label>Projekty</ion-label>
							</ion-segment-button>
							
							<ion-segment-button value="Videa">
								<ion-label>Všetky videá</ion-label>
							</ion-segment-button>
						</ion-segment>

						<hr class="segment-hr">

						<div v-if="activeTab == 'Projekty'">
							<ion-row class="mt-1">
								<A-course-card  v-for="course in theme.courses" :key="course.id" 
									:course="course"
									@click="$router.push({name: 'course', params: {courseSlug: course.slug}})"/>
							</ion-row>
						</div>

						<div v-if="activeTab == 'Videa'">
							<w-loadable :items="videos" skelet="none" messageEmpty="Neboli nájdené žiadne videá pre tento kurz">
								<A-videos-thumb v-if="!$isWeb.value" class="video-thumb" :videos="videos"/>
								<A-tiktok-feed v-else @toggleVideoFullscreen="_toggleVideoFullscreen" :videos="videos" :disableFirstVideoAutoplay="false" :showWebVideo="$isWeb.value" 
									@update="updateVideos($event)" ref="a-tiktok-feed" />
								<A-fullscreen-video class="fullscreen-video" v-if="videoFullscreen" @closeFullscreen="_toggleVideoFullscreen" :videos="videos" :currentVideoIndex="currentVideoIndex" />
							</w-loadable>
						</div>
					</w-loadable>
				</div>

			</w-loadable>
		</ion-content>
	</ion-page>
</template>

<script>
import ACourseCard 		from '@/plugins/lib@enum/theme/components/a-course-card.vue'
import AVideosThumb 	from '@/plugins/lib@enum/theme/components/a-videos-thumb.vue'
import ATiktokFeed 		from '@/plugins/lib@feed/_components/a-feed.vue'
import AFullscreenVideo from '@/plugins/lib@feed/_components/a-fullscreen-video.vue'
import wAxios 			from '@/plugins/w/axios'
import { mapGetters} 	from 'vuex'
import { personCircleOutline, schoolOutline, checkmarkDoneCircleOutline, logInOutline } from 'ionicons/icons'

const api = {
	read: (slug)	 	=> wAxios.get_auth_data(`v1/themes/${slug}`),
	readVideos: (id) 	=> wAxios.get_auth_data(`v1/themes/${id}/feed`),
	confirmUser: (id, user_id) 	=> wAxios.get_auth_data(`v1/themes/${id}/users/${user_id}/lessonstats`)
}

export default {
	components: {
		ACourseCard,
		AVideosThumb,
		ATiktokFeed,
		AFullscreenVideo
	},

	data() {
		return {
			personCircleOutline,
			schoolOutline,
			checkmarkDoneCircleOutline,
			logInOutline,
			activeTab: 'Projekty',
			disableScroll: false,
			videoFullscreen: false,
			currentVideoIndex: 0,
			theme: null,
			videos: null,
			stats: {
				pending: 0,
				verified: 0
			}
		}
	},

	computed: {
		...mapGetters('wAuth', [
			'isLoggedIn',
			'user'
		]),
	},

	created() {
		this.eventBus.on('course:update', async (course) => {
			let targetCourse = this.theme.courses.find(c => c.id == course.id)
			targetCourse = course
		})
	},

	async ionViewWillEnter() {
		this.theme	= null
		this.videos = null
		// this.activeTab = 'Projekty'

		try {
			this.theme 	= await api.read(this.$route.params.themeSlug)
			const usedId = []

			const videos = await api.readVideos(this.theme.id)
			this.videos = videos.filter(video=>{
				if (usedId.includes(video.id)) return false
				usedId.push(video.id)
				return true
			})
			if (this.isLoggedIn) this.stats = await api.confirmUser(this.theme.id, this.user.id)
		} catch (error) {
			this.theme 	= {error: error}
		}
	},

	methods: {

		_toggleVideoFullscreen(videoIndex) {
			this.currentVideoIndex = videoIndex
			if (!this.videoFullscreen) {
				this.videoFullscreen = true
				this._toggleScroll()
			} else {
				this.videoFullscreen = false
				this._toggleScroll()
			}
		},

		_toggleScroll() {
			if (!this.disableScroll){
				this.disableScroll = true
			} else {
				this.disableScroll = false
			}
			console.log("scroll toggled")
		},

		onChange() {
			this.activeTab = this.activeTab == 'Videa' ? 'Projekty' : 'Videa'
		},

		contentScroll() {
			if (this.$isWeb.value && this.activeTab == 'Videa') this.$refs['a-tiktok-feed']?._playWebVideo()
		},
	},

}
</script>

<style lang="sass" scoped>
.fullscreen-video
	position: fixed
	top: 0
	left: 0
	width: 100%
	height: 100%
	z-index: 999

.theme-information-wrapper
	background-color: white
	padding: 0 20px 15px
	cursor: default

	.theme-img
		height: 110px
		max-height: 20vh

	hr
		margin-bottom: 5px

	h4
		margin: 10px 0 5px

	.status-wrapper
		max-width: 350px
		margin: auto

		ion-icon
			margin-right: 5px

		p
			line-height: 15px
			color: black

.orange
	color: orange

.green
	color: green

.segment-hr
	margin-bottom: 5px

@media only screen and (min-width: 1140px)
	.ion-padding
		padding-left: 0
		padding-right: 0

</style>